exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-menus-menu-1-hoz-tsx": () => import("./../../../src/pages/menus/menu1hoz.tsx" /* webpackChunkName: "component---src-pages-menus-menu-1-hoz-tsx" */),
  "component---src-pages-menus-menu-1-tsx": () => import("./../../../src/pages/menus/menu1.tsx" /* webpackChunkName: "component---src-pages-menus-menu-1-tsx" */),
  "component---src-pages-menus-menu-2-hoz-tsx": () => import("./../../../src/pages/menus/menu2hoz.tsx" /* webpackChunkName: "component---src-pages-menus-menu-2-hoz-tsx" */),
  "component---src-pages-menus-menu-2-tsx": () => import("./../../../src/pages/menus/menu2.tsx" /* webpackChunkName: "component---src-pages-menus-menu-2-tsx" */),
  "component---src-pages-menus-menu-hoz-dynamic-tsx": () => import("./../../../src/pages/menus/menu-hoz-dynamic.tsx" /* webpackChunkName: "component---src-pages-menus-menu-hoz-dynamic-tsx" */),
  "component---src-pages-menus-menu-hoz-video-test-tsx": () => import("./../../../src/pages/menus/menu-hoz-video-test.tsx" /* webpackChunkName: "component---src-pages-menus-menu-hoz-video-test-tsx" */),
  "component---src-pages-menus-menu-vert-dynamic-tsx": () => import("./../../../src/pages/menus/menu-vert-dynamic.tsx" /* webpackChunkName: "component---src-pages-menus-menu-vert-dynamic-tsx" */)
}

